body {
  font-size: calc(10px + 2vmin);
  color: #3A456B;
  background-color: #E5E5E5;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 95vh;
}

main h1 { color: #FF5071; }

.lineBlock {
  display: flex;
  width: 10vh;
  height: 1vh;
  background: #3A456B;
  z-index: 10;
  margin-bottom: 20vh;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cstmInput {
  border-radius: 20px;
  width: 70vw;
  max-width: 900px;
  background: #fff;
  color: #a3a3a3;
  font: inherit;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 22px 18px;

}
.basicdBtn {
  font-size: 1.4rem;
  font-weight: bold;
  font-variant: small-caps;
  border: none;
  cursor: pointer;
  transition: .3s;
}
.cstmBtnGo {
  background: #FF5071;
  margin-left: -25px;
  border-radius: 20px;
  padding: 18px;
  color: #fff;
  z-index: 1;
}
.cstmBtnGo:hover {
  transform: scale(1.1);
}
.cstmBtnCopy {
  background: none;
  color: #FF5071;
  margin-left: 10px;
}
.cstmBtnCopy:hover {
  text-decoration: underline;
}

.resultsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 10px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  max-width: 100vw;
}

.loaderTextCont {
  overflow-wrap: break-word;
  width: 80vw;
  max-width: 600px;
}

/*###############################
# Footer
###############################*/

footer a, footer {
  color: #3A456B;
  opacity: 0.8;
  text-decoration: none;
  font-size: 1rem;
}
footer a:hover {
  text-decoration: underline;
}

/*###############################
# loaderCSS (made by: https://codepen.io/ykadosh)
###############################*/
svg {
  overflow: visible;
  width: 100px;
  height: 150px;
}
svg g {
  animation: slide 2s linear infinite;
}
svg g:nth-child(2) {
  animation-delay: 0.5s;
}
svg g:nth-child(2) path {
  animation-delay: 0.5s;
  stroke-dasharray: 0px 158px;
  stroke-dashoffset: 1px;
}
svg path {
  stroke: #FF5071;
  stroke-width: 20px;
  stroke-linecap: round;
  fill: none;
  stroke-dasharray: 0 157px;
  stroke-dashoffset: 0;
  animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

@keyframes slide {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(50px);
  }
}
@keyframes escalade {
  0% {
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: -156px;
  }
}

